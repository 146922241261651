import clsx from "clsx";
import React, {useContext} from "react";
import {GlobalStateContext} from "../../providers/GlobalStateProvider";
import useGStyles from "../../assets/global-styles";
import moment from "moment";

export default (props) => {

    //глобальный state
    const {
        pubConfig,
    } = useContext(GlobalStateContext);


    const gClasses = useGStyles();

    return (
        <section className={gClasses.footer}>


            <div className={gClasses.footer_followSection}>

                <div className={gClasses.footer_followSectionHeader}>
                    Наши страницы
                </div>

                <div className={gClasses.footer_followSectionBtns}>
                    {
                        pubConfig.footerSocialButtons.map(s => (<img onClick={() => window.open(s.link, "_blank")} className={gClasses.footer_followSectionBtn} key={s.id} src={s.icon} />))
                    }
                </div>

            </div>

            <div className={gClasses.footer_copyright}>

                <div className={gClasses.footer_copyrightLogo}>
                    <img src="/svg/logo.svg"/>
                </div>

                <div className={gClasses.footer_copyrightText}>
                    <p>© {moment().format("YYYY")} Бронирую!<sup>®</sup></p>
                    <p>Сервис по поиску и бронированию жилья в Сочи.</p>
                    <p>Бронирую! - это сервис для поиска краткосрочной аренды жилья в Сочи. Снять посуточно квартиру или апартаменты в Сочи без посредников по самым выгодным ценам.</p>
                </div>

            </div>

        </section>
    )
}