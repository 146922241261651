import React, { createContext, useState } from "react";
import moment from "moment";
import {initLightboxJS, SlideshowLightbox} from "lightbox.js-react";
import 'lightbox.js-react/dist/index.css';
import {TelegramFooterIcon, VKFooterIcon} from "../assets/svg-icons.js";

export const GlobalStateContext = createContext();

const GlobalStateProvider = ({children}) => {

    const [backdrop, setBackdrop] = useState(false);
    const [toast, setToast] = useState({open: false, severity: "error", "text": ""});

    //Lightbox
    const [lightbox, setLightbox] = useState({
        show: false,
        images: [],
        imgIndex: 0
    });


    const pubConfig = {
        "version:": "1.1",
        "_app_url": "http://localhost:3001",
        "app_url": "https://бронирую.рус",
        "ao_url": "https://агент.онлайн",
        "typeVerificationCode": "demo",
        "entities": [
            { "name": "reType", "ru": "Объект", "ruAbrr": "объект", "color": "#fce4d4", "icon": "ReTypeIcon", "matchAI": false, "descAI": "тип объекта (искаль только: дом, квартира, жилое помещение, апартаменты, ЖП, нежилое помещение)"},
            { "name": "houseName", "ru": "ЖК", "ruAbrr": "жк", "color": "#d8f8c4", "icon": "HouseNamesIcon", "matchAI": false, "descAI": "жилой комплекс (название начинается с ЖК, АК)"},
            { "name": "address", "ru": "Адрес", "ruAbrr": "адрес", "color": "#fcfcac", "icon": "LocationIcon", "matchAI": true, "descAI": "адрес"},
            { "name": "rooms", "ru": "Комнаты", "ruAbrr": "комн.", "color": "#def4fe", "icon": "RoomsIcon", "matchAI": true, "descAI": "количество комнат"},
            { "name": "area", "ru": "Площадь", "ruAbrr": "S", "color": "#dcdcfc", "icon": "AreaIcon", "matchAI": true, "descAI": "площадь недвижимости"},
            { "name": "price", "ru": "Цена", "ruAbrr": "₽", "color": "#c4fccd", "icon": "PriceIcon", "matchAI": true, "descAI": "цена"},
            { "name": "commission", "ru": "Комиссия", "ruAbrr": "(₽)", "color": "#fce4ab", "icon": "CommissionIcon", "matchAI": true, "descAI": "комиссия (число обычно указывается за ценой в скобках)"},
            { "name": "floor", "ru": "Этаж", "ruAbrr": "э", "color": "#fdccd4", "icon": "FloorIcon", "matchAI": true, "descAI": "этаж"},
            { "name": "furnish", "ru": "Отделка", "ruAbrr": "рем.", "color": "#eccdfc", "icon": "RollerIcon", "matchAI": true, "descAI": "отделка/ремонт (ремонт, РМТ, черновая...)"},
            { "name": "mortage", "ru": "Ипотека", "ruAbrr": "ипот.", "color": "#fcfca4", "icon": "MortgageIcon", "matchAI": true, "descAI": "есть ли ипотека"},
            { "name": "landArea", "ru": "S земли", "ruAbrr": "S зем.", "color": "#ceeeff", "icon": "LandAreaIcon", "matchAI": true, "descAI": "площадь участка в сотках"},
            { "name": "rent", "ru": "Аренда", "ruAbrr": "аренда", "color": "#85c9fb", "icon": "BadIcon", "matchAI": true, "descAI": "если сдается в аренду"},
            { "name": "exclude", "ru": "Исключить", "ruAbrr": "stop", "color": "#e38484", "icon": "BlockIcon", "matchAI": false}
        ],
        "records_url": "https://файлы.агент.онлайн/records",
        "image_url": "https://файлы.агент.онлайн/files",
        "maps": {
            "defaultZoom": 10,
            "viewCardZoom": 15
        },
        "supportTg": "agent_assist",
        "housesOptions": {

            "type": [
                {"value": "flat_complex", "title": "Жилой комплекс (ЖК)"},
                {"value": "apartments_complex", "title": "Апартаментный комплекс (АК)"},
                {"value": "building_complex", "title": "Многоквартирный дом (МД)"},
                {"value": "hotel", "title": "Отель"},
                {"value": "flat_house", "title": "Жилой дом (ЖД)"},
                {"value": "cottage_village", "title": "Котеджный поселок или ТХ (КП и ТХ)"}
            ],

            "status": [
                {"value": "flat", "title": "Квартира"},
                {"value": "apartments", "title": "Апартаменты"},
                {"value": "living_quarters", "title": "Жилое помещение"},
                {"value": "non_resid_premises", "title": "Нежилое помещение"},
                {"value": "cottage", "title": "Частный дом"}
            ],

            "houseClass": [
                {"value": "economy_class", "title": "Эконом", "rating": 0},
                {"value": "comfort_class", "title": "Комфорт", "rating": 10},
                {"value": "comfort_plus_class", "title": "Комфорт+", "rating": 20},
                {"value": "business_class", "title": "Бизнес", "rating": 40},
                {"value": "elite_class", "title": "Элит", "rating": 70},
                {"value": "deluxe_class", "title": "Делюкс", "rating": 100}
            ],

            "territory": [
                {"value": "open", "title": "Открытая", "rating": 0},
                {"value": "closed", "title": "Закрытая", "rating": 2},
                {"value": "cl_guarded", "title": "Закрытая охраняемая", "rating": 3}
            ],

            "heating": [
                {"value": "central_heating", "title": "Центральное", "rating": 4},
                {"value": "individual_gas_boiler", "title": "Индив. газовый котел (ИГК)", "rating": 5},
                {"value": "electric_heating", "title": "Электрическое", "rating": -7},
                {"value": "individual_heating_point", "title": "Индив. тепловой пункт (ИТП)", "rating": 5}
            ],

            "sewerage": [
                {"value": "central_sewerage", "title": "Центральная", "rating": 5},
                {"value": "local_treatment_facilities", "title": "ЛОС", "rating": -10}
            ],

            "tags": [
                {"value": "pool", "title": "Бассейн", "rating": 10},
                {"value": "elevator", "title": "Лифт", "rating": 4},
                {"value": "CCTV", "title": "Видеонаблюдение", "rating": 3},
                {"value": "commerce", "title": "Коммерческие площади", "rating": 5},
                {"value": "playground_children", "title": "Детская площадка", "rating": 3},
                {"value": "playground_sport", "title": "Спортивная площадка", "rating": 3},
                {"value": "rest_zone", "title": "Зоны отдыха", "rating": 2},
                {"value": "landscape_design", "title": "Ланшафтный дизайн", "rating": 2},
                {"value": "spa", "title": "SPA-центр", "rating": 15},
                {"value": "restaurant", "title": "Ресторан", "rating": 3},
                {"value": "fitness", "title": "Фитнес", "rating": 5},
                {"value": "checkpoint", "title": "КПП", "rating": 2},
                {"value": "concierge", "title": "Консьерж", "rating": 3},
                {"value": "tennis_court", "title": "Теннисный корт", "rating": 3},
                {"value": "kindergarten", "title": "Детский сад", "rating": 5}
            ],

            "paymentOptions": [
                {"value": "mortgage", "title": "Ипотека"},
                {"value": "cash", "title": "Наличный расчет"},
                {"value": "installment_plan", "title": "Рассрочка"}
            ],

            "purchaseOptions": [
                {"value": "contract_of_sale", "title": "Договор купли-продажи", "rating": 20},
                {"value": "federal_law_214", "title": "ФЗ-214", "rating": 20},
                {"value": "preliminary_agreement", "title": "Пред. договор", "rating": -30},
                {"value": "investment_agreement", "title": "Договор инвестир.", "rating": -30},
                {"value": "loan_agreement", "title": "Договор займа", "rating": -30}
            ],

            "parkingOptions": [
                {"value": "underground_parking", "title": "Подземный", "rating": 5},
                {"value": "house_parking", "title": "Придомовой", "rating": 2},
                {"value": "guarded_house_parking", "title": "Охраняемый придомовой", "rating": 3}
            ],

            "waterSupplyOptions": [
                {"value": "central_cws", "title": "Центральное (ХВС)", "rating": 2},
                {"value": "well_cws", "title": "Скважина (ХВС)", "rating": -7},
                {"value": "central_hws", "title": "Центральное (ГВС)", "rating": 3},
                {"value": "individual_heating_point_hws", "title": "ИТП (ГВС)", "rating": 5},
                {"value": "individual_gas_boiler_hws", "title": "ИГК (ГВС)", "rating": 5},
                {"value": "electric_boiler_hws", "title": "Эл. бойлер (ГВС)", "rating": -5}
            ]
        },
        "support_tg": "agent_assist",
        "adRooms": [
            {
                "name": "Студия",
                "code": "fa_reoptions_1",
                "platforms": {
                    "Avito": "студия",
                    "Cian": 9,
                    "DomClick": 9,
                    "none": "студия"
                }
            },
            {
                "name": "1-к",
                "code": "fa_reoptions_2",
                "platforms": {
                    "Avito": 1,
                    "Cian": 1,
                    "DomClick": 1,
                    "none": 1
                }
            },
            {
                "name": "1.5-к",
                "code": "fa_reoptions_3",
                "platforms": {
                    "Avito": 2,
                    "Cian": 2,
                    "DomClick": 2,
                    "none": 2
                }
            },
            {
                "name": "2-к",
                "code": "fa_reoptions_4",
                "platforms": {
                    "Avito": 2,
                    "Cian": 2,
                    "DomClick": 2,
                    "none": 2
                }
            },
            {
                "name": "3-к",
                "code": "fa_reoptions_5",
                "platforms": {
                    "Avito": 3,
                    "Cian": 3,
                    "DomClick": 3,
                    "none": 3
                }
            },
            {
                "name": "4-к",
                "code": "fa_reoptions_6",
                "platforms": {
                    "Avito": 4,
                    "Cian": 4,
                    "DomClick": 4,
                    "none": 4
                }
            }
        ],
        "districts": {
            "sochi": [
                {
                    "name": "Адлер",
                    "code": "b_district_2",
                    "extremes": {
                        "lat": [
                            43.39515133385407,
                            43.495585367949225
                        ],
                        "long": [
                            39.882661640704114,
                            40.046107671399525
                        ]
                    }
                },
                {
                    "name": "Сириус",
                    "code": "b_district_1",
                    "extremes": {
                        "lat": [
                            43.38032769992123,
                            43.42873953923515
                        ],
                        "long": [
                            39.9230681666141,
                            40.00852965380425
                        ]
                    }
                },
                {
                    "name": "Кудепста",
                    "code": "b_district_8",
                    "extremes": {
                        "lat": [
                            43.490284797809466,
                            43.51415133571835
                        ],
                        "long": [
                            39.875996833040304,
                            39.9269909908315
                        ]
                    }
                },
                {
                    "name": "Хоста",
                    "code": "b_district_7",
                    "extremes": {
                        "lat": [
                            43.50035926339883,
                            43.562095484607184
                        ],
                        "long": [
                            39.80564642425981,
                            39.89186066862604
                        ]
                    }
                },
                {
                    "name": "Центр Сочи",
                    "code": "b_district_3",
                    "extremes": {
                        "lat": [
                            43.544326092814856,
                            43.65839443223213
                        ],
                        "long": [
                            39.6985485610539,
                            39.79058016257016
                        ]
                    }
                },
                {
                    "name": "Мамайка",
                    "code": "b_district_5",
                    "extremes": {
                        "lat": [
                            43.6214525860418,
                            43.66135714041688
                        ],
                        "long": [
                            39.66369758425933,
                            39.7269333439946
                        ]
                    }
                },
                {
                    "name": "Дагомыс",
                    "code": "b_district_6",
                    "extremes": {
                        "lat": [
                            43.645546465709835,
                            43.73448073100142
                        ],
                        "long": [
                            39.64285505952789,
                            39.70026877233172
                        ]
                    }
                },
                {
                    "name": "Лазаревский р-он",
                    "code": "b_district_10",
                    "extremes": {
                        "lat": [
                            43.65227754041673,
                            44.046850170470364
                        ],
                        "long": [
                            39.14435009993329,
                            39.65469937361501
                        ]
                    }
                },
                {
                    "name": "Красная Поляна",
                    "code": "b_district_4",
                    "extremes": {
                        "lat": [
                            43.614645889302835,
                            43.72542002262396
                        ],
                        "long": [
                            40.14891247786261,
                            40.35782437361457
                        ]
                    }
                },
                {
                    "name": "Мацеста",
                    "code": "b_district_9",
                    "extremes": {
                        "lat": [
                            43.53664903388027,
                            43.61749439568646
                        ],
                        "long": [
                            39.778479709156535,
                            39.839646196427054
                        ]
                    }
                }
            ]
        },
        "banners": [
            {
                "id": "excursions_and_tours",
                "name": "Экскурсии и туры",
                "cover": "/images/excursions_and_tours.jpg",
                "url": "/excursions-and-tours/",
                "color": "#FF5722",
            },
            {
                "id": "popular-locations",
                "name": "Знаковые места и популярные локации Сочи",
                "cover": "/images/popular-location.jpg",
                "url": "/popular-locations/",
                "color": "#7546E6",
            },
            {
                "id": "buy_apartments",
                "name": "Купить недвижимость для сдачи в аренду или отдыха",
                "cover": "/images/buy_apartments.jpg",
                "url": "/buy-apartments/",
                "color": "#00B2FF",
            },
        ],
        "footerSitemap": [
            {
                id: "guests",
                name: "Гостям",
                header: true,
                show: true,
            },
            {
                id: "guest_support",
                name: "Поддержка",
                link: "/support",
                show: true,
            },
            {
                id: "catalog",
                name: "Каталог объектов",
                link: "go-to-catalog",
                show: true,
            },
            {
                id: "popular-houses",
                name: "Популярные комплексы",
                link: "view-popular-houses",
                show: true,
            },
            /*
            {
                id: "popular-re",
                name: "Горячие объекты",
                link: "/popular-re",
                show: true,
            },
             */
            {
                id: "book-service-re",
                name: "Объекты размещения сервиса Бронирую!",
                link: "view-book-service-re",
                show: true,
            },
            {
                id: "excursions-and-tours",
                name: "Экскурсии и туры в Сочи и Абхазии",
                link: "/excursions-and-tours",
                show: true,
            },
            {
                id: "popular-locations",
                name: "Знаковые места и популярные локации",
                link: "/popular-locations",
                show: true,
            },
            //
            {
                id: "owners",
                name: "Владельцам жилья",
                header: true,
                show: true,
            },
            {
                id: "renovation",
                name: "Ремонт квартиры «под ключ»",
                link: "/renovation",
                show: true,
            },
            {
                id: "buy-apartments",
                name: "Купить недвижимость для сдачи в аренду или отдыха",
                link: "/buy-apartments",
                show: true,
            },
            {
                id: "crm-agent",
                name: "CRM-система для агентов по недвижимости",
                link: "/crm-agent",
                show: true,
            },
            {
                id: "contacts",
                name: "Контакты и обратная связь",
                link: "/contacts",
                show: true,
                last: true,
            },
        ],
        "footerSocialButtons": [
            {
                id: "telegram",
                icon: "/svg/telegram.svg",
                link: "https://t.me/agent_assist",
            },
            {
                id: "vk",
                icon: "/svg/vk.svg",
                link: "https://vk.com/book.russia",
            },
        ],
        "bookServiceCompanyId": "gGhCshC75cELe5cko",
        "bookingCalendar": {
            "colors":{
                "pastBooking": "#EEEEEE",
                "futureBooking": "#E5F6E6",
                "notConfirmedBooking": "#CBECFF",
                "forDeletionBooking": "#F6E5E5"
            }
        },
        "ourObjectsGallery": [
            {
                "id": "a1",
                "category": "apartaments",
                "name": "Ремонт квартиры под ключ",
                "description": "Евро 2-к квартира 45м², г. Сочи",
                "images": [
                    "images/renovation-apartaments/1/1.jpg",
                    "images/renovation-apartaments/1/2.jpg",
                    "images/renovation-apartaments/1/3.jpg",
                    "images/renovation-apartaments/1/4.jpg"
                ]
            },
            {
                "id": "a2",
                "category": "apartaments",
                "name": "Ремонт квартиры под ключ",
                "description": "Студия 34м², г. Сочи",
                "images": [
                    "images/renovation-apartaments/2/1.jpg",
                    "images/renovation-apartaments/2/2.jpg",
                    "images/renovation-apartaments/2/3.jpg",
                    "images/renovation-apartaments/2/4.jpg",
                    "images/renovation-apartaments/2/5.jpg"
                ]
            },
            {
                "id": "a3",
                "category": "apartaments",
                "name": "Ремонт квартиры под ключ",
                "description": "Двухуровневая квартира 62м², г. Сочи",
                "images": [
                    "images/renovation-apartaments/3/1.jpg",
                    "images/renovation-apartaments/3/2.jpg",
                    "images/renovation-apartaments/3/3.jpg",
                    "images/renovation-apartaments/3/4.jpg",
                    "images/renovation-apartaments/3/5.jpg",
                    "images/renovation-apartaments/3/6.jpg"
                ]
            },
            {
                "id": "a4",
                "category": "apartaments",
                "name": "Ремонт квартиры под ключ",
                "description": "3-к квартира 115м², г. Сочи",
                "images": [
                    "images/renovation-apartaments/4/1.jpg",
                    "images/renovation-apartaments/4/2.jpg",
                    "images/renovation-apartaments/4/3.jpg",
                    "images/renovation-apartaments/4/4.jpg",
                    "images/renovation-apartaments/4/5.jpg",
                    "images/renovation-apartaments/4/6.jpg"
                ]
            },
            {
                "id": "a5",
                "category": "apartaments",
                "name": "Ремонт квартиры под ключ",
                "description": "Евро 2-к квартира 38м², г. Сочи",
                "images": [
                    "images/renovation-apartaments/5/1.jpg",
                    "images/renovation-apartaments/5/2.jpg",
                    "images/renovation-apartaments/5/3.jpg",
                    "images/renovation-apartaments/5/4.jpg",
                    "images/renovation-apartaments/5/5.jpg",
                    "images/renovation-apartaments/5/6.jpg"
                ]
            },
            {
                "id": "a6",
                "category": "apartaments",
                "name": "Ремонт квартиры под ключ",
                "description": "Евро 2-к квартира 55м², г. Сочи",
                "images": [
                    "images/renovation-apartaments/6/1.jpg",
                    "images/renovation-apartaments/6/2.jpg",
                    "images/renovation-apartaments/6/3.jpg",
                    "images/renovation-apartaments/6/4.jpg",
                    "images/renovation-apartaments/6/5.jpg"
                ]
            },
            {
                "id": "a306",
                "category": "apartaments",
                "name": "Ремонт квартиры под ключ",
                "description": "Студия 20м² в Адлерском районе",
                "images": [
                    "images/renovation-apartaments/a306/1.jpg",
                    "images/renovation-apartaments/a306/2.jpg",
                    "images/renovation-apartaments/a306/3.jpg",
                    "images/renovation-apartaments/a306/4.jpg",
                    "images/renovation-apartaments/a306/5.jpg",
                    "images/renovation-apartaments/a306/6.jpg",
                    "images/renovation-apartaments/a306/7.jpg"
                ]
            },
            {
                "id": "a7",
                "category": "apartaments",
                "name": "Ремонт квартиры под ключ",
                "description": "Студия 22м², г. Сочи",
                "images": [
                    "images/renovation-apartaments/7/1.jpg",
                    "images/renovation-apartaments/7/2.jpg",
                    "images/renovation-apartaments/7/3.jpg",
                    "images/renovation-apartaments/7/4.jpg"
                ]
            },
            {
                "id": "gazpromITC",
                "category": "industrial",
                "cover": "images/renovation-industrial/gazprom-transgaz-itc/cover.jpg",
                "name": "Пожарная сигнализация, сети связи, пром. автоматизация, системы оповещения",
                "description": "Газпром Трансгаз ИТЦ",
                "images": [
                    "images/renovation-industrial/gazprom-transgaz-itc/1.jpg",
                    "images/renovation-industrial/gazprom-transgaz-itc/2.jpg"
                ]
            },
            {
                "id": "nft",
                "category": "industrial",
                "cover": "images/renovation-industrial/nft/cover.jpg",
                "name": "Фасад здания, пожарная сигнализация, системы оповещения",
                "description": "Нефтегаздеталь АБК, цеха",
                "images": [
                    "images/renovation-industrial/nft/1.jpg",
                    "images/renovation-industrial/nft/2.jpg",
                    "images/renovation-industrial/nft/3.jpg"
                ]
            },
            {
                "id": "nagornyy",
                "category": "industrial",
                "cover": "images/renovation-industrial/zhk-nagornyy/cover.jpg",
                "name": "Кладка блоков «паротерм», машинная штукатурка мест общего пользования",
                "description": "Жилой квартал Нагорный",
                "images": [
                    "images/renovation-industrial/zhk-nagornyy/1.jpg",
                    "images/renovation-industrial/zhk-nagornyy/2.jpg",
                    "images/renovation-industrial/zhk-nagornyy/3.jpg",
                    "images/renovation-industrial/zhk-nagornyy/4.jpg",
                    "images/renovation-industrial/zhk-nagornyy/5.jpg"
                ]
            },
            {
                "id": "medPersonalDoctor",
                "category": "industrial",
                "cover": "images/renovation-industrial/personal-doctor/cover.jpg",
                "name": "Отделка под ключ",
                "description": "Частная клиника Личный доктор",
                "images": [
                    "images/renovation-industrial/personal-doctor/1.jpg",
                    "images/renovation-industrial/personal-doctor/2.jpg",
                    "images/renovation-industrial/personal-doctor/3.jpg",
                    "images/renovation-industrial/personal-doctor/4.jpg",
                ]
            },
            {
                "id": "medCentral",
                "category": "industrial",
                "cover": "images/renovation-industrial/med-central/cover.jpg",
                "name": "Слаботочные системы",
                "description": "Чайковская городская поликлиника",
                "images": [
                    "images/renovation-industrial/med-central/1.jpg",
                ]
            },
            {
                "id": "medChildren",
                "category": "industrial",
                "cover": "images/renovation-industrial/med-children/cover.jpg",
                "name": "Слаботочные системы",
                "description": "Чайковская детская городская больница",
                "images": [
                    "images/renovation-industrial/med-children/1.jpg",
                ]
            },
        ],
        "weAreAtWorkGallery": [
            {
                "id": "1",
                "src": "/images/renovation-we-are-at-work/1.jpg"
            },
            {
                "id": "2",
                "src": "/images/renovation-we-are-at-work/1.jpg"
            }
        ]
    };

    return (
        <GlobalStateContext.Provider
            value={{
                backdrop, setBackdrop,
                toast, setToast,
                lightbox, setLightbox,
                pubConfig,

            }}
        >
            {children}
        </GlobalStateContext.Provider>
    );
};

export default GlobalStateProvider;
