import { Button } from "@mui/material";
import React, {useContext, useState, useRef} from "react";
import { UserContext } from "../../providers/UserProvider";
import { useUserRedirect } from "../../utils/useUserRedirect";
import useStyles from "./styles";
import useGStyles from "../../assets/global-styles";
import clsx from "clsx";
import {useLocation, useNavigate} from "react-router";
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';
import IconButton from "@mui/material/IconButton";
import moment from 'moment';
import {PinIcon, ArrowDownIcon, CalendarIcon, GuestsIcon, FireV2Icon, RightArrowIcon, BookServiceSignIcon} from "../../assets/svg-icons.js";
import MainTopBanner from "./MainTopBanner.jsx";
import PopularHouses from "./PopularHouses.jsx";
import BookServiceRe from "../Catalog/BookServiceRe.jsx";
import StarIcon from '@mui/icons-material/Star';
import PopularRe from "../Catalog/PopularRe.jsx";
import {GlobalStateContext} from "../../providers/GlobalStateProvider";
import {getTextDistricts} from "../../utils/functions.js";
import SearchIcon from '@mui/icons-material/Search';

const Main = (props) => {

    const {
        filterState,
        onOpenDateFilterClick,
        onOpenDistrictFilterClick,
        onViewHouse,
        onViewRe,
        onSearchRe,
        onViewBookServiceRe,
        onViewPopularHouses,
        incGuestsCount,
        backUrl,
    } = props;

    //глобальный state
    const {
        pubConfig,
    } = useContext(GlobalStateContext);

    const classes = useStyles();
    const gClasses = useGStyles();

    const nav = useNavigate();

    //console.log("re-render Main");

    const goToPage = (url) => {
        nav(url);
    };

    const goToLink = (link) => {

        switch (link) {
            case "go-to-catalog":
                return onSearchRe();

            case "view-popular-houses":
                return onViewPopularHouses();

            case "view-book-service-re":
                return onViewBookServiceRe();

            default:
                return nav(link);
        }

    }

    return (

        <div className={gClasses.contentScrollWrapper}>

            <MainTopBanner/>

            <section className={gClasses.inputsSearchModule}>

                <div className={gClasses.inputsSearchModule__form}>

                    <div className={gClasses.districtsPseudoSelect} onClick={() => onOpenDistrictFilterClick()}>

                        <PinIcon style={{fontSize: 19, color: "#56B9F2"}}/>

                        <div className={clsx(gClasses.districtsPseudoSelect__status, (filterState.dates.districts.length?gClasses.districtsPseudoSelect__checked:null))}>
                            {getTextDistricts(filterState.dates.districts)}
                        </div>

                        <ArrowDownIcon style={{fontSize: 16}}/>

                    </div>

                    <div className={gClasses.inputsSearchModule__pseudoInputDates} onClick={() => onOpenDateFilterClick()}>

                        <div className={gClasses.inputsSearchModule__pseudoInputDatesCheckIn}>
                            <span>Заезд</span>
                            {filterState.dates?.startDate && moment(filterState.dates.startDate).format("DD.MM.YY") || null}
                        </div>

                        <div className={gClasses.inputsSearchModule__pseudoInputDatesCheckOut}>
                            <span>Выезд</span>
                            {filterState.dates?.endDate && moment(filterState.dates.endDate).format("DD.MM.YY") || null}
                        </div>

                        <CalendarIcon style={{fontSize: 16, marginLeft: 20, color: "#56B9F2"}}/>

                    </div>

                    <div className={gClasses.inputsSearchModule__guestsWrapper}>

                        <div className={gClasses.inputsSearchModule__guestsLabel}><GuestsIcon style={{fontSize: 24, color: "#56B9F2"}}/>Гости</div>

                        <div className={gClasses.inputsSearchModule__guests}>
                            <IconButton size="small" onClick={() => incGuestsCount(-1)}><RemoveIcon/></IconButton>
                            <div className={gClasses.inputsSearchModule__guestsCount}>{filterState.dates.guestsCount}</div>
                            <IconButton size="small" onClick={() => incGuestsCount(1)}><AddIcon/></IconButton>
                        </div>

                    </div>

                    <Button
                        className={clsx(gClasses.largeBtn, gClasses.findBtn)}
                        fullWidth
                        size="large"
                        variant="contained"
                        onClick={onSearchRe}
                        //startIcon={<SearchIcon/>}
                        color="red"
                    >
                        Найти жильё
                    </Button>


                </div>



            </section>

            <section className={classes.popularHouses}>

                <h2 className={clsx(gClasses.h2, gClasses.h2WithIcon)}><StarIcon/><span>Популярные комплексы</span></h2>

                <PopularHouses
                    backUrl={backUrl}
                    filter={filterState}
                    onViewHouse={onViewHouse}
                />

            </section>

            <section className={classes.publishApartments}>
                <h2>Публикуйте ваши объекты размещения в сервисе <span>Бронирую!</span> бесплатно</h2>

                <Button
                    className={gClasses.mediumBtn}
                    fullWidth
                    variant="contained"
                    onClick={() => window.open("https://агент.онлайн/auth?modules=rent", "_blank")}
                    color="green"
                >
                    Добавить объект размещения
                </Button>

            </section>

            <section className={classes.popularRe}>

                <h2 className={clsx(gClasses.h2, gClasses.h2WithIcon)}><FireV2Icon/><span>Новые объекты</span></h2>

                <PopularRe
                    backUrl={backUrl}
                    filter={filterState}
                    onViewRe={onViewRe}
                />


            </section>

            <section className={classes.banners}>

                {
                    pubConfig.banners.map(banner => (

                        <div key={banner.id} className={classes.bannerItem} onClick={() => goToPage(banner.url)}>
                            <div className={classes.bannerItem__header}><span style={{background: banner.color}}>{banner.name}</span></div>
                            <img className={classes.bannerItem__cover} src={banner.cover} alt={banner.name}/>
                            <Button className={gClasses.mediumBtn} style={{background: banner.color}} variant="contained" onClick={() => goToPage(banner.url)} startIcon={<RightArrowIcon style={{fontSize: 12, color: "#fff"}}/>}>Подробнее</Button>
                        </div>

                    ))
                }

            </section>

            <section className={classes.bookServiceApartaments}>

                <div className={classes.bookServiceApartamentsHeader}>
                    <img src="/svg/logo-sign.svg" alt="бронирую!"/>
                    <h2 className={gClasses.h2}>Апартаменты от сервиса <span style={{color: "#7546E6"}}>Бронирую!</span></h2>
                </div>

                <div className={clsx(gClasses.textBlock, classes.bookServiceApartamentsContent)}>
                    <p><b>Мы подготовили для вас собственный номерной фонд, состоящий из объектов размещения категорий комфорт, комфорт+ и бизнес.</b></p>
                    <p>Будем рады видеть вас в объектах размещения сервиса <span style={{color: "#7546E6"}}>Бронирую!</span> Уверены, что ваше проживание будет максимально комфортным, а отдых и запоминающимся!</p>
                </div>

                <BookServiceRe
                    backUrl={backUrl}
                    filter={filterState}
                    onViewRe={onViewRe}
                />

                <div style={{padding: "0 15px", marginTop: 15}}>
                    <Button
                        className={gClasses.mediumBtn}
                        fullWidth
                        variant="contained"
                        onClick={onViewBookServiceRe}
                        color="violet"
                    >
                        Объекты от сервиса Бронирую!
                    </Button>
                </div>


            </section>

            <section className={classes.aboutAO}>

                <div className={classes.aboutAOLogo}>
                    <img src="/svg/agent_logo.svg" alt="CRM Агент"/>
                </div>

                <h2 className={gClasses.h2}>Управляйте вашими объектами в CRM «Агент»</h2>

                <div className={clsx(gClasses.textBlock, classes.aboutAOContent)}>
                    <p>Работайте с клиентами, управляйте вашими бронированиями, ведите финансовый учет, предоставляйте собственникам онлайн-доступ к отчетам, а также используйте синхронизацию с популярными площадками!</p>
                    <h3>«Агент» - это бесплатная CRM-система от сервиса бронирую!</h3>
                </div>

                <Button
                    className={gClasses.mediumBtn}
                    fullWidth
                    variant="contained"
                    onClick={() => window.open("https://агент.онлайн/auth?modules=rent", "_blank")}
                    color="green"
                >
                    Открыть CRM «Агент»
                </Button>

            </section>

            <section className={gClasses.footer}>

                <div className={gClasses.footer__delimiter}>

                </div>

                {
                    pubConfig.footerSitemap.map(item => (
                        <div onClick={() => goToLink(item.link)} key={item.id} className={clsx((item.header?gClasses.footer__itemHeader:gClasses.footer__item), (item.last?gClasses.footer__lastItem:null))}>
                            {item.name}
                        </div>
                    ))
                }

                <div className={gClasses.footer_followSection}>

                    <div className={gClasses.footer_followSectionHeader}>
                        Наши страницы
                    </div>

                    <div className={gClasses.footer_followSectionBtns}>
                        {
                            pubConfig.footerSocialButtons.map(s => (<img onClick={() => window.open(s.link, "_blank")} className={gClasses.footer_followSectionBtn} key={s.id} src={s.icon} />))
                        }
                    </div>

                </div>

                <div className={gClasses.footer_copyright}>

                    <div className={gClasses.footer_copyrightLogo}>
                        <img src="/svg/logo.svg"/>
                    </div>

                    <div className={gClasses.footer_copyrightText}>
                        <p>© {moment().format("YYYY")} Бронирую!<sup>®</sup></p>
                        <p>Сервис по поиску и бронированию жилья в Сочи.</p>
                        <p>Бронирую! - это сервис для поиска краткосрочной аренды жилья в Сочи. Снять посуточно квартиру или апартаменты в Сочи без посредников по самым выгодным ценам.</p>
                    </div>

                </div>

            </section>

        </div>
    );

}

export default Main;
