import React from "react";
import { Routes, Route } from "react-router-dom";
import Auth from "../pages/Auth/Auth";
import User from "../pages/User/User";
import Catalog from "../pages/Catalog/Catalog"
import Support from "../pages/Support/Support.jsx";
import ExcursionsAndTours from "../pages/ExcursionsAndTours/ExcursionsAndTours.jsx"
import PopularLocations from "../pages/PopularLocations/PopularLocations.jsx"
import BuyApartments from "../pages/BuyApartments/BuyApartments.jsx"
import CrmAgent from "../pages/CrmAgent/CrmAgent.jsx"
import Contacts from "../pages/Contacts/Contacts.jsx"
import QrKurortnyy from "../pages/qr-houses/QrKurortnyy.jsx"
import Renovation from "../pages/Renovation/Renovation.jsx";

//загружает нужный компонент в зависимости от url
const Router = () => {
    return (
        <Routes>
            <Route path="/auth/login" element={<Auth type={"login"} />} />
            <Route path="/auth/register" element={<Auth type={"register"} />} />
            <Route path="/profile" element={<User/>}/>
            <Route path="/re/:reNumberId?" element={<Catalog/>} />
            <Route path="/h/:houseNumberId" element={<Catalog/>} />
            <Route path="/support" element={<Support/>}/>
            <Route path="/excursions-and-tours" element={<ExcursionsAndTours/>}/>
            <Route path="/popular-locations" element={<PopularLocations/>}/>
            <Route path="/renovation" element={<Renovation/>}/>
            <Route path="/buy-apartments" element={<BuyApartments/>}/>
            <Route path="/crm-agent" element={<CrmAgent/>}/>
            <Route path="/contacts" element={<Contacts/>}/>
            <Route path="/qr/kurortnyy" element={<QrKurortnyy/>}/>


            {/* /:indexCategory? - должно быть в самом низу */}
            <Route path="/:indexCategory?" element={<Catalog/>}/>


        </Routes>
        );
    };

export default Router;
