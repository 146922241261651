import {makeStyles} from "@mui/styles";

const onePx = 0.0625;

//цвета логотипа
const colorViolet = "#7546E6";
const colorBlue = "#00B2FF";
const colorGreen = "#62BC00";
const colorYellow = "#FFB500";
const colorRed = "#FF5722";
const colorBlack = "#323232";

export default makeStyles((theme) => ({

    mainBanner: {
      position: "relative",
      width: "100%",
      "& img": {
          width: "100%",
          display: "block",
      },
      "& h1": {
          margin: 0,
          color: "#fff",
          position: "absolute",
          fontFamily: "Montserrat",
          fontSize: 35*onePx+"rem",
          fontWeight: 700,
          left: 14,
          bottom: 25,
      }
    },

    bannerWrapper: {
        position: "relative",
        overflow: "hidden",
        width: "100%",
        marginBottom: 15,
        "& img": {
            width: "100%",
            borderRadius: 14,
        },
        "& h2": {
            position: "absolute",
            top: "7%",
            left: "7%",
            fontFamily: "Montserrat",
            fontSize: 22*onePx+"rem",
            fontWeight: 700,
            color: "#000",
            lineHeight: "1.2em",
        },
        "& h2::before": {
            display: "block",
            position: "absolute",
            content: '""',
            top: -9,
            left: 0,
            width: 46,
            height: 3,
        },
        "& h2.bannerWrapperRent::before": {
            background: "#00B2FF",
        },
        "& h2.bannerWrapperRenovation::before": {
            background: "#7546E6",
        },
        "& h2.bannerWrapperTrustManagement::before": {
            background: "#62BC00",
        },
        "& button": {
            position: "absolute",
            top: "calc(17% + 75px)",
            left: "7%",
        },

    }

}))