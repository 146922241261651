import {makeStyles} from "@mui/styles";

const onePx = 0.0625;

//цвета логотипа
const colorViolet = "#7546E6";
const colorBlue = "#00B2FF";
const colorGreen = "#62BC00";
const colorYellow = "#FFB500";
const colorRed = "#FF5722";
const colorBlack = "#323232";

export default makeStyles((theme) => ({

    ourClients: {
        display: "flex",
        width: "100%",
        marginLeft: -6,
        overflowX: "auto",
        "&::-webkit-scrollbar": {
            display: "none",
        },
        marginBottom: 20,
    },
    ourClientsWrapper: {
        display: "flex",

    },
    ourClientsItem: {
        width: 150,
        height: 112.5,
        //background: "#F4F6F9",
        borderRadius: 8,
        //border: "1px solid rgba(0, 32, 51, 0.05)",
        overflow: "hidden",
        position: "relative",
        //boxShadow: "0px 2px 2px rgba(0, 32, 51, 0.02), 0px 2px 8px rgba(0, 32, 51, 0.16)",
        margin: 5,
        flex: "none",
        boxShadow: "0px 2px 8px 0px #00203329",
        "& img": {
            width: "100%",
        }
    },

    equipment: {
        display: "flex",
        width: "100%",
        paddingLeft: 15,
        overflowX: "auto",
        "&::-webkit-scrollbar": {
            display: "none",
        },
        //marginBottom: 55,
    },
    equipmentWrapper: {
        display: "flex",
        paddingBottom: 25,
    },
    equipmentItem: {
        width: 168,
        height: 118,
        borderRadius: 14,
        overflow: "hidden",
        position: "relative",
        marginRight: 5,
        flex: "none",
        "& img": {
            width: 168,
            height: 118,
            objectFit: "cover",
        },
        boxShadow: "0px 8px 24px 0px rgba(0, 32, 51, 0.12)",
        border: "1px solid rgba(63, 85, 106, 0.28)",
    },
    ourObjects: {
        padding: "30px 0",
        background: "#f1f1f1",
        width: "100%",
        "& > h2": {
            marginBottom: 20,
            fontSize: 24*onePx+"rem",
            lineHeight: "1.2em",
            position: "relative",
            fontWeight: 700,
            marginTop: 18,
        },
        "& > h2::before": {
            display: "block",
            position: "absolute",
            content: '""',
            top: -15,
            left: 0,
            width: 38,
            height: 4,
            background: "#00B2FF",
        },
    },
    ourObjectsWrapper: {
        display: "flex",
        overflowX: "auto",
        width: "100%",
        background: "#f1f1f1",
        "&::-webkit-scrollbar": {
            display: "none",
        },
        marginBottom: 20,
        paddingLeft: 15,
    },
    ourObjectsWrapperSecond: {
        display: "flex",
    },
    ourObjectsItem: {
        marginRight: 10,
        width: 314,
        position: "relative",
        "& img": {
            display: "block",
            width: 314,
            height: 235,
            borderRadius: "15px 15px 0 0",
            overflow: "hidden",
            objectFit: "cover",
        },
    },
    ourObjectsItemText: {
        height: 74,
        width: 314,
        border: "1px solid rgba(63, 85, 106, 0.28)",
        borderRadius: "0 0 15px 15px ",
        overflow: "hidden",
        marginTop: -1,
        background: "#fff",
        padding: 14,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
    },
    ourObjectsItemIndText: {
        height: 110,
        position: "relative",
    },
    ourObjectsItemName: {
        fontSize: 16*onePx+"rem",
        fontWeight: 600,
        margin: "0!important",
    },
    ourObjectsItemDesc: {
        fontSize: 14*onePx+"rem",
        fontWeight: 500,
        color: "rgba(101, 119, 128, 1)",
        margin: "0!important",
    },
    ourObjectsItemCover: {
        position: "absolute",
        zIndex: 2,
        bottom: 97,
        left: 14,
        width: 74,
        height: 74,
        border: "1px solid #3F556A47",
        boxShadow: "0px 8px 24px 0px #0020331F",
        borderRadius: 14,
        overflow: "hidden",
        "& img": {
            width: 74,
            height: 74,
            objectFit: "cover",
        }
    },
    weAreAtWork: {
        display: "flex",
        width: "100%",
        paddingLeft: 15,
        overflowX: "auto",
        "&::-webkit-scrollbar": {
            display: "none",
        },
        marginBottom: 10,
    },
    weAreAtWorkWrapper: {
        display: "flex",
        paddingBottom: 25,
    },
    weAreAtWorkItem: {
        width: 314,
        height: 235,
        borderRadius: 14,
        overflow: "hidden",
        position: "relative",
        marginRight: 5,
        flex: "none",
        "& img": {
            width: 314,
            height: 235,
            objectFit: "cover",
        },
        //boxShadow: "0px 8px 24px 0px rgba(0, 32, 51, 0.12)",
        //border: "1px solid rgba(63, 85, 106, 0.28)",
    },

    prices: {
        width: "100%",
        height: 357,
        background: "url(/images/renovation-price-banner.jpg)",
        backgroundSize: "cover",
        paddingTop: 15,
        "& > h2": {
            marginBottom: 20,
            fontSize: 24*onePx+"rem",
            lineHeight: "1.2em",
            position: "relative",
            fontWeight: 700,
            marginTop: 35,
            textAlign: "center",
        },
        "& > h2::before": {
            display: "block",
            position: "absolute",
            content: '""',
            top: -15,
            left: "calc(50% - 23px)",
            width: 46,
            height: 4,
            background: "#62BC00",
        },
    },
    priceInfoBanner: {
        width: 250,
        height: 243,
        background: "#fff",
        display: "flex",
        flexWrap: "wrap",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
        margin: "auto",
        borderRadius: 14,
        boxShadow: "0px 12px 28px 0px #0020331F",
        padding: "35px 15px",
        marginBottom: 15,
    },
    priceInfoBanner__header: {
        fontFamily: "Inter",
        fontSize: 20*onePx+"rem",
        fontWeight: 700,
        color: "#62BC00",
    },
    priceInfoBanner__text: {
        fontSize: 16*onePx+"rem",
        fontWeight: 400,
    },
    contacts: {
        marginBottom: 35,
        padding: "0 10px",
        "& > h2": {
            marginBottom: 35,
            fontSize: 24*onePx+"rem",
            lineHeight: "1.2em",
            position: "relative",
            fontWeight: 700,
            marginTop: 45,
            textAlign: "center",
        },
        "& > h2::before": {
            display: "block",
            position: "absolute",
            content: '""',
            top: -15,
            left: "calc(50% - 23px)",
            width: 46,
            height: 4,
            background: "#FFB500",
        },
    },
    contactsWrapper: {
        display: "flex",

    },
    contacts__item: {
        padding: "0 5px",
        width: "50%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
        "& img": {
            width: 135,
            height: 135,
            borderRadius: "50%",
            overflow: "hidden",
            marginBottom: 17,
        },
        "& .MuiButtonBase-root": {
            marginTop: 10,
            width: "100%",
        }
    },
    contacts__name: {
        fontFamily: "Inter",
        fontSize: 17*onePx+"rem",
        fontWeight: 600,
        textAlign: "center",
        margin: 0,
        marginBottom: 10,
    },
    contacts__job_title: {
        fontFamily: "Inter",
        fontSize: 16*onePx+"rem",
        fontWeight: 400,
        textAlign: "center",
        color: "#657780",
        margin: 0,
        marginBottom: "7px!important",
    },
    contacts__phone: {
        fontFamily: "Inter",
        fontSize: 17*onePx+"rem",
        fontWeight: 600,
        textAlign: "center",
        margin: 0,
        color: "#2F2E36",
        textDecoration: "none",
        marginBottom: "7px!important",
    },
    textPadding: {
        padding: "0 15px",
    },
    mainBannerWrapper: {
        position: "relative",
        paddingBottom: 45,
    },
    mainBannerLogo: {
        width: 168,
        height: 90,
        position: "absolute",
        zIndex: 2,
        bottom: 0,
        left: "calc(50% - 94px)",
        background: "#fff",
        borderRadius: 14,
        boxShadow: "0px 8px 24px 0px #0020331F",
        border: "1px solid #3F556A47",
        padding: 15,
        "& img": {
            display: "block",
            width: "100%",
        }
}

}));