import { Button } from "@mui/material";
import React, {useContext, useState} from "react";
import useGStyles from "../../assets/global-styles";
import clsx from "clsx";
import {GlobalStateContext} from "../../providers/GlobalStateProvider";
import TextPageTpl from "../../components/TextPageTpl/TextPageTpl.jsx";
import BreadCrumbs from "../../components/BreadCrumbs/BreadCrumbs";
import useStyles from "./styles.js";
import DownloadIcon from '@mui/icons-material/Download';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import Drawer from "@mui/material/Drawer/Drawer";
import ButtonGroup from "@mui/material/ButtonGroup";

const Renovation = () => {

    const gClasses = useGStyles();
    const classes = useStyles();

    //глобальный state
    const {
        pubConfig,
        backdrop, setBackdrop,
        toast, setToast,
        lightbox, setLightbox,
    } = useContext(GlobalStateContext);


    //ActionSheet
    const [showActionSheet, setShowActionSheet] = useState(false);
    const [actionSheetType, setActionSheetType] = useState(null);

    const viewGalleryOurObjects = (id) => {

        const images = pubConfig.ourObjectsGallery.find(item => item.id == id).images.map(src => ({
            src,
        }));

        setLightbox({
            ...lightbox,
            show: true,
            images,
            imgIndex: 0,
            options: {
                showThumbnails: true,
            }
        });
    };

    const onDownloadPrice = (re) => {
        setActionSheetType("action_download_price");
        setShowActionSheet(true);
    }

    const downloadPrice = (url) => {
        setShowActionSheet(false);
        window.open(url, "_blank");
    }

    const renderActionSheetSwitch = () => {
        switch (actionSheetType) {
            case "action_download_price":
                return (
                    <ButtonGroup
                        variant="text"
                        orientation="vertical"
                    >
                        <Button
                            onClick={() => downloadPrice("/files/price_calculation_studio_20m2.pdf")}
                            size="large"
                        >
                            Студия 20м²
                        </Button>
                        <Button
                            onClick={() => downloadPrice("/files/price_calculation_46m2.pdf")}
                            size="large"
                        >
                            Евро 2-к квартира 46м²
                        </Button>
                    </ButtonGroup>
                );

            default:
                return null;
        }
    }

    const onCloseActionSheet = () => {
        setShowActionSheet(false);
    }

    const viewGalleryWeAreAtWork = (id) => {

        const imgIndex = pubConfig.weAreAtWorkGallery.map(i => i.id).indexOf(id);

        const images = pubConfig.weAreAtWorkGallery.map(i => ({
            src: i.src,
        }));

        setLightbox({
            ...lightbox,
            show: true,
            images,
            imgIndex,
            options: {
                showThumbnails: true,
            }
        });
    }


    return (
        <TextPageTpl withMiniFooter={true} withoutPadding={true}>

            <BreadCrumbs stack={[{name: "Ремонт «под ключ»"}]} style={{padding: "15px 15px 0 15px", marginBottom: 20}}/>

            <div className={clsx(gClasses.contentWrapper__text, classes.textPadding)}>
                <h1>Доверьте ремонт вашей квартиры экспертам команды <span style={{color: "#7546E6"}}>Бронирую!</span> </h1>

                <div className={classes.mainBannerWrapper}>
                    <img src="/images/renovation_our_team.jpg" alt="" className={clsx(gClasses.textPageImg, gClasses.mb0)}/>
                    <div className={classes.mainBannerLogo}>
                        <img src="/svg/renovation-logo.svg" />
                    </div>
                </div>

                <h2>Почему мы лучшие в своем деле?</h2>

                <p><b>Мы знаем о строительстве и ремонте всё, и даже больше!</b></p>
                <p>Наши инженеры обладают высшим профильным образованием подкрепленным десятками лет трудового стажа в области строительно-монтажных и пуско-наладочных работ.</p>
            </div>

            <div className={clsx(classes.ourObjects)}>

                <div className={clsx(gClasses.contentWrapper__text, classes.textPadding)}>
                    <h2>Реализованные проекты</h2>
                    <h3><span className={gClasses.tagStyle} style={{background: "#62BC00"}}>Квартиры и апартаменты</span></h3>
                </div>

                <div className={classes.ourObjectsWrapper}>
                    <div className={classes.ourObjectsWrapperSecond}>
                        {
                            pubConfig.ourObjectsGallery.filter(i => i.category == "apartaments").map(item => {

                                return (
                                    <div key={item.id} className={classes.ourObjectsItem} onClick={() => viewGalleryOurObjects(item.id)}>
                                        <img src={item.images[0]}/>
                                        <div className={classes.ourObjectsItemText}>
                                            <p className={classes.ourObjectsItemName}>{item.name}</p>
                                            <p className={classes.ourObjectsItemDesc}>{item.description}</p>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>

                <div className={clsx(gClasses.contentWrapper__text, classes.textPadding)}>
                    <h3 style={{marginTop: 35}}><span className={gClasses.tagStyle} style={{background: "#62BC00"}}>Коммерч. и промышленные объекты</span></h3>
                </div>

                <div className={classes.ourObjectsWrapper}>
                    <div className={classes.ourObjectsWrapperSecond}>
                        {
                            pubConfig.ourObjectsGallery.filter(i => i.category == "industrial").map(item => {

                                return (
                                    <div key={item.id} className={classes.ourObjectsItem} onClick={() => viewGalleryOurObjects(item.id)}>
                                        <img src={item.images[0]}/>
                                        <div className={classes.ourObjectsItemCover}>
                                            <img src={item.cover} alt=""/>
                                        </div>
                                        <div className={clsx(classes.ourObjectsItemText, classes.ourObjectsItemIndText)}>

                                            <p className={classes.ourObjectsItemName}>{item.name}</p>
                                            <p className={classes.ourObjectsItemDesc}>{item.description}</p>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>

            </div>

            <div className={clsx(gClasses.contentWrapper__text, classes.textPadding)}>

                <h2 className={gClasses.mb10}>Безкомпромисное качество работ</h2>

                <p>Мы управляем своим собственным номерным фондом апартаментов и знаем насколько важно получить высокое качество ремонта. Помимо этого, исходя из многолетнего опыта работы, мы детально понимаем нюансы и неочевидные детали процесса.</p>
                <p>В нашей работе мы используем только профессиональное оборудование ведущих мировых производителей, благодаря чему добиваемся высочайшего качества обработки материалов, точности элементов, установленных параметров смесей, а также соблюдений всех норм технологических процессов.</p>

            </div>

            <div className={classes.equipment}>
                <div className={classes.equipmentWrapper}>

                    <div className={classes.equipmentItem}>
                        <img src="/images/equipment/pft.jpg" alt=""/>
                    </div>

                    <div className={classes.equipmentItem}>
                        <img src="/images/equipment/wandeli.jpg" alt=""/>
                    </div>

                    <div className={classes.equipmentItem}>
                        <img src="/images/equipment/ada.jpg" alt=""/>
                    </div>

                    <div className={classes.equipmentItem}>
                        <img src="/images/equipment/bosch.jpg" alt=""/>
                    </div>

                    <div className={classes.equipmentItem}>
                        <img src="/images/equipment/festool.jpg" alt=""/>
                    </div>

                    <div className={classes.equipmentItem}>
                        <img src="/images/equipment/lossew.jpg" alt=""/>
                    </div>

                    <div className={classes.equipmentItem}>
                        <img src="/images/equipment/makita.jpg" alt=""/>
                    </div>

                    <div className={classes.equipmentItem}>
                        <img src="/images/equipment/dewalt.jpg" alt=""/>
                    </div>

                    <div className={classes.equipmentItem}>
                        <img src="/images/equipment/hilti.jpg" alt=""/>
                    </div>

                    <div className={classes.equipmentItem}>
                        <img src="/images/equipment/korshun.jpg" alt=""/>
                    </div>

                    <div className={classes.equipmentItem}>
                        <img src="/images/equipment/zubr.jpg" alt=""/>
                    </div>

                    <div className={classes.equipmentItem}>
                        <img src="/images/equipment/candan.jpg" alt=""/>
                    </div>




                </div>
            </div>

            <div className={clsx(gClasses.contentWrapper__text, classes.textPadding)} style={{marginBottom: 35}}>
                <h2 className={gClasses.mb10}>Наш рабочий процесс</h2>
                <p>Сочи - курортный город и большинство собственников не проживают здесь, мы это понимаем и строим наш рабочий процесс так, чтобы работы по ремонту велись практичести без участия собственника жилья.</p>
                <p>Ремонт квартиры ведется по рабочей документации по-этапно, после выполнения этапа работ производится олпата за него. Все согласования чистовых материалов, мебели и техники происходят удаленно.</p>
                <p>Мы даем гарантию 1 год на все выполненные работы.</p>
            </div>

            {/*
            <div className={classes.weAreAtWork}>
                <div className={classes.weAreAtWorkWrapper}>
                    {
                        pubConfig.weAreAtWorkGallery.map(item => (
                            <div key={item.id} className={classes.weAreAtWorkItem} onClick={() => viewGalleryWeAreAtWork(item.id)}>
                                <img src={item.src}/>
                            </div>
                        ))
                    }
                </div>
            </div>
            */}


            <div className={clsx(classes.prices)}>
                <h2>Стоимость ремонта</h2>

                <div className={classes.priceInfoBanner}>
                    <p className={classes.priceInfoBanner__header}>от 17.990 ₽/м²</p>
                    <p className={classes.priceInfoBanner__text}><b>Калькуляция стоимости работ по ремонту</b> для cтудии 20м² или евро 2-к квартиры 46м² вы можете скачать ниже</p>
                    <Button
                        className={gClasses.mediumBtn}
                        variant="contained"
                        style={{background: "#62BC00", height: 40}}
                        size="large"
                        startIcon={<DownloadIcon/>}
                        onClick={onDownloadPrice}
                    >
                        Скачать смету в pdf
                    </Button>
                </div>
            </div>

            <div className={clsx(classes.contacts)}>
                <h2>Свяжитесь с нами</h2>

                <div className={classes.contactsWrapper}>

                    <div className={classes.contacts__item}>
                        <img src="/images/top_manager.jpg"/>
                        <p className={classes.contacts__name}>Георгий</p>
                        <p className={classes.contacts__job_title}>Ведущий менеджер</p>
                        <a href="tel:+79225009990" className={classes.contacts__phone}>+7 (922) 500-99-90</a>
                        <Button
                            startIcon={<WhatsAppIcon/>}
                            className={gClasses.mediumBtn}
                            variant="contained"
                            style={{background: "#4CAF50"}}
                            size="large"
                            onClick={() => window.open("https://wa.me/79225009990", "_blank")}
                        >
                            WhatsApp
                        </Button>
                    </div>

                    <div className={classes.contacts__item}>
                        <img src="/images/chief_engineer.jpg"/>
                        <p className={classes.contacts__name}>Андрей</p>
                        <p className={classes.contacts__job_title}>Главный инженер</p>
                        <a href="tel:+79223227472" className={classes.contacts__phone}>+7 (922) 322-74-72</a>
                        <Button
                            startIcon={<WhatsAppIcon/>}
                            className={gClasses.mediumBtn}
                            variant="contained"
                            style={{background: "#4CAF50"}}
                            size="large"
                            onClick={() => window.open("https://wa.me/79223227472", "_blank")}
                        >
                            WhatsApp
                        </Button>
                    </div>

                </div>

            </div>

            <Drawer
                anchor="bottom"
                open={showActionSheet}
                onClose={() => onCloseActionSheet()}
                className={gClasses.actionSheet}
            >
                <div className={gClasses.actionSheetWrapper}>
                    <div className={gClasses.drawerFrame}>
                        {renderActionSheetSwitch()}
                        <Button className={gClasses.actionSheetWrapperCancelBtn} variant="text" size="large" onClick={() => setShowActionSheet(false)}>Отмена</Button>
                    </div>
                </div>

            </Drawer>


        </TextPageTpl>
    );
};

export default Renovation;
