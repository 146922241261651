import React, {useState, useContext, useEffect} from 'react'
import { GlobalStateContext } from "../providers/GlobalStateProvider.jsx";

import useStyles from "./styles";
import useGStyles from "../assets/global-styles";
import CustomSnackbar from "./CustomSnackbar/CustomSnackbar";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import {useNavigate} from "react-router";
import {initLightboxJS, SlideshowLightbox} from "lightbox.js-react";

const Layout = ({children}) => {

	const classes = useStyles();
	const gClasses = useGStyles();

	//глобальный state
	const {
		backdrop, setBackdrop,
		toast, setToast,
		lightbox, setLightbox,
	} = useContext(GlobalStateContext);

	useEffect(() => {
		initLightboxJS("D406-DC22-4DDC-2D6D", "Individual");
	});

	return (
		<>

			<div className={gClasses.mainWrapper} >
				{children}

				<SlideshowLightbox
					showControlsBar={false}
					startingSlideIndex={lightbox.imgIndex}
					images={lightbox.images}
					showThumbnails={true}
					open={lightbox.show}
					onClose={() =>{
						setLightbox({
							...lightbox,
							show: false,
							images: [],
						});
					}}
				>
				</SlideshowLightbox>

			</div>

			<Backdrop
				sx={{ color: '#fff', zIndex: 100}}
				open={backdrop}
			>
				<CircularProgress color="inherit" />
			</Backdrop>

			<CustomSnackbar toast={toast} setToast={setToast}/>

		</>
	)
}

export default Layout;
